import { NextPage, GetStaticProps } from 'next';

import SEO from 'components/home/SEO';
import HeroSection from 'components/home/HeroSection';
import BlogSection from 'components/home/BlogSection';
import Layout from 'components/home/Layout';
import FeatureSection from 'components/home/FeatureSection';
import StepsSection from 'components/home/StepsSection';
import TeamSection from 'components/home/TeamSection';
import PricingSection from 'components/home/PricingSection';
import { HomeAttributes } from 'interfaces/home';
// import { domainName } from 'config/global';
import Link from 'next/link';
import LoginForm from 'components/forms/LoginForm';
import domainNameOptions from 'utils/domainUtils';
// import { dom } from '@fortawesome/fontawesome-svg-core';

interface Props {
  content: { attributes: HomeAttributes };
  domainName: string;
}

const HomePage: NextPage<Props> = ({ domainName, content }) => {
  const { attributes } = content;

  console.log('domain', domainName);

  const showLandingPage = domainNameOptions[domainName].showLandingPage;

  if (showLandingPage) {
    return (
      <>
        <SEO domainName={domainName} />
        <Layout domainName={domainName}>
          <div className="m-auto max-w-7xl">
            <HeroSection
              version={attributes.hero_version}
              title={attributes.hero_title}
              description={attributes.hero_description}
              image={attributes.hero_image}
            />
            <FeatureSection
              version={attributes.feature_version}
              title={attributes.feature_title}
              description={attributes.feature_description}
              features={attributes.features}
            />
            <StepsSection
              version={attributes.steps_version}
              steps={attributes.steps}
              image={attributes.steps_image}
            />
            <PricingSection
              title={attributes.pricing_title}
              description={attributes.pricing_description}
              plans={attributes.plans}
            />
            <TeamSection
              version={attributes.team_version}
              title={attributes.team_title}
              description={attributes.team_description}
              team={attributes.team}
            />
            <BlogSection
              version={attributes.blog_version}
              title={attributes.blog_title}
              description={attributes.blog_description}
              slugs={attributes.posts}
            />
          </div>
        </Layout>
      </>
    );
  }
  return (
    <>
      <SEO domainName={domainName} />
      <Layout domainName={domainName}>
        <div className="flex flex-col min-h-screen bg-gray-50">
          <div className="mx-4 mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="mt-24 text-center">
              <h2 className="mt-6 text-3xl font-extrabold text-center text-gray-900">
                Log in
              </h2>
              <p className="mt-2 text-center text-gray-600 text-md">
                {"Don't have an account? "}
                <Link legacyBehavior href="/signup">
                  <a className="text-royal-blue-500">Sign up</a>
                </Link>
              </p>
            </div>
            <div className="px-4 py-8 mt-8 bg-white rounded-lg shadow-lg sm:px-10">
              <LoginForm />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const domainName = context.params.host as string;
  const content = await import(
    `../../content/pages/${domainNameOptions[domainName].homeMd}`
  );

  return { props: { domainName, content: content.default } };
};

export async function getStaticPaths() {
  return {
    paths: ['/software.ercexpert.com', '/brightdayerc.com', '/localhost'],
    fallback: 'blocking',
  };
}

export default HomePage;
